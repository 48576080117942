// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ValidationBadge, Label } from "components/form";
import { classes } from "utils/classes";

import styles from "./autocomplete.scss";
import { List } from "./List";
import { ListItem } from "./ListItem";
import { Search } from "./Search";

export class AutocompleteRenderer extends Component {
  static propTypes = {
    search: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    inputPlaceholder: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    items: PropTypes.array,
    dataPending: PropTypes.bool,
    dataError: PropTypes.string,
    dataLoaded: PropTypes.bool,
    handleListScroll: PropTypes.func,
    highlightedItemIdx: PropTypes.number.isRequired,
    changeHighlightedItemIdx: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    selectedItem: PropTypes.object,
    isListOpen: PropTypes.bool.isRequired,
    changeIsListOpen: PropTypes.func.isRequired,
    className: PropTypes.string,
    withoutIcon: PropTypes.bool,
    isCustomValueAccepted: PropTypes.bool,
    disabled: PropTypes.bool,

    // prop required for Formik managed field
    name: PropTypes.string,
  };

  render() {
    const {
      search,
      label,
      required,
      tooltip,
      error,
      touched,
      inputPlaceholder,
      items,
      dataPending,
      dataError,
      dataLoaded,
      handleListScroll,
      highlightedItemIdx,
      changeHighlightedItemIdx,
      selectItem,
      changeSearch,
      selectedItem,
      isListOpen,
      changeIsListOpen,
      className,
      withoutIcon,
      isCustomValueAccepted,
      disabled,
      name,
    } = this.props;

    return (
      <div
        className={classes(styles.wrapper, className)}
        data-test-id="autocomplete"
      >
        <Label required={required} text={label} tooltip={tooltip}>
          <Search
            value={search}
            placeholder={inputPlaceholder}
            changeSearch={changeSearch}
            changeHighlightedItemIdx={changeHighlightedItemIdx}
            highlightedItemIdx={highlightedItemIdx}
            selectItem={selectItem}
            itemsCount={items.length}
            selectedItem={selectedItem}
            changeIsListOpen={changeIsListOpen}
            withoutIcon={withoutIcon}
            isCustomValueAccepted={isCustomValueAccepted}
            disabled={disabled}
            name={name}
          />
          <List
            dataPending={dataPending}
            dataError={dataError}
            dataLoaded={dataLoaded}
            handleScroll={handleListScroll}
            isOpen={isListOpen}
          >
            {items.map((item, idx) => (
              <ListItem
                key={item.id}
                label={item.label}
                idx={idx}
                highlightedItemIdx={highlightedItemIdx}
                selectItem={selectItem}
                changeHighlightedItemIdx={changeHighlightedItemIdx}
              />
            ))}
          </List>
        </Label>
        <ValidationBadge error={error} touched={touched} />
      </div>
    );
  }
}

export default AutocompleteRenderer;
