import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Pill } from "components/elements";
import { GrabsonIcon, ImgIcon } from "components/icons";
import { Section, Row } from "components/layout";
import arrowActive from "images/arrowActive.svg";
import arrowInactive from "images/arrowInactive.svg";
import { useSnapState, KEYS } from "pages/Snap/shared/SnapState/useSnapState";
import { trans } from "src/translations";

import styles from "./SnapStatusTree.scss";

const STATUSES = {
  NEW: "NEW",
  DRAFT: "DRAFT",
  ACCEPTED: "ACTIVE",
  SUBMISSION_STARTED: "SUBMITTED",
  SUBMITTED: "SUBMITTED",
  NEW_VER_SUBMITTED: "NEW VER. SUBMITTED",
  NOT_SUBMITTED: "NOT_SUBMITTED",
};

const StatusColumn = ({
  isStatusActive,
  isDraftActive,
  label,
  discardDraft,
}) => {
  return (
    <div className={styles.column}>
      <Pill
        className={isStatusActive ? styles.pillActive : styles.pillNotActive}
        text={label}
        icon="ok-filled"
        iconSize="lg"
        dataTestId={`pill${isStatusActive ? "-active" : ""}`}
      />
      <div className={styles.draftColumn}>
        <Pill
          className={isDraftActive ? styles.pillActive : styles.pillNotActive}
          text={STATUSES.DRAFT}
          icon="ok-filled"
          iconSize="lg"
          dataTestId={`pill${isDraftActive ? "-active" : ""}`}
        />
        {isDraftActive && (
          <Button
            className={styles.discardButton}
            type="plain"
            onClick={discardDraft}
          >
            <GrabsonIcon
              name="close-filled"
              font="micro"
              size="xl"
              className={styles.discardIcon}
            />
          </Button>
        )}
      </div>
    </div>
  );
};

StatusColumn.propTypes = {
  discardDraft: PropTypes.func,
  isStatusActive: PropTypes.bool,
  isDraftActive: PropTypes.bool,
  label: PropTypes.string,
};

export const SnapStatusTree = ({ discardDraft }) => {
  const { appStates } = useSnapState();

  return (
    <Section className={styles.statusContainer} header={trans.STATUS()}>
      <Row>
        <StatusColumn
          isStatusActive={
            appStates[0]?.key === KEYS.NEW || appStates[0]?.key === KEYS.DRAFT
          }
          isDraftActive={appStates[0]?.key === KEYS.DRAFT}
          label={STATUSES.NEW}
          discardDraft={discardDraft}
        />
        <div className={styles.arrow}>
          <ImgIcon
            src={
              appStates[0]?.key === KEYS.CREATED ? arrowActive : arrowInactive
            }
          />
        </div>
        <StatusColumn
          isStatusActive={appStates[0]?.key === KEYS.CREATED}
          isDraftActive={
            appStates[0]?.key === KEYS.CREATED &&
            appStates[1]?.key === KEYS.DRAFT
          }
          label={STATUSES.SUBMITTED}
          discardDraft={discardDraft}
        />
        <div className={styles.arrow}>
          <ImgIcon
            src={
              appStates[0]?.key === KEYS.ACTIVE &&
              appStates[1]?.key !== KEYS.CREATED
                ? arrowActive
                : arrowInactive
            }
          />
        </div>
        <StatusColumn
          isStatusActive={appStates[0]?.key === KEYS.ACTIVE}
          isDraftActive={
            appStates[0]?.key === KEYS.ACTIVE &&
            appStates[1]?.key === KEYS.DRAFT
          }
          label={STATUSES.ACCEPTED}
          discardDraft={discardDraft}
        />
        <div className={styles.arrow}>
          <ImgIcon
            src={
              appStates[0]?.key === KEYS.ACTIVE &&
              appStates[1]?.key === KEYS.CREATED
                ? arrowActive
                : arrowInactive
            }
          />
        </div>
        <StatusColumn
          isStatusActive={appStates[1]?.key === KEYS.CREATED}
          isDraftActive={appStates[2]?.key === KEYS.DRAFT}
          label={STATUSES.NEW_VER_SUBMITTED}
          discardDraft={discardDraft}
        />
      </Row>
    </Section>
  );
};
SnapStatusTree.propTypes = {
  discardDraft: PropTypes.func,
};
