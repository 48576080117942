// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Checkbox } from "components/form/elements";
import { GrabsonIcon } from "components/icons";

import { DeviceFamilyRow } from "./DeviceFamilyRow";
import styles from "./OrganizationRow.scss";

export class OrganizationRow extends Component {
  static propTypes = {
    organization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      deviceFamilies: PropTypes.array.isRequired,
    }).isRequired,
    isModelChecked: PropTypes.func.isRequired,
    onModelChecked: PropTypes.func.isRequired,
  };

  state = {
    expanded: false,
  };

  expandRow = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  isChecked() {
    const { organization, isModelChecked } = this.props;
    const isFamilyChecked = (f) => f.models.some((m) => isModelChecked(m.id));
    return organization.deviceFamilies.some(isFamilyChecked);
  }

  render() {
    const {
      organization: { name, deviceFamilies },
      onModelChecked,
      isModelChecked,
    } = this.props;
    const { expanded } = this.state;

    return (
      <li
        className={styles.container}
        data-test-id="asset-device-organization-row"
      >
        <div className={styles.row}>
          <Checkbox
            onChange={Function.prototype}
            checked={this.isChecked()}
            value={name}
            className={styles.checkbox}
            disabled
          />
          <span onClick={this.expandRow} className={styles.expandContainer}>
            <GrabsonIcon
              name={expanded ? "arrow-up" : "arrow-down"}
              className={styles.arrowIcon}
              font="micro"
            />
            {name}
          </span>
        </div>
        {expanded &&
          deviceFamilies.map((family) => (
            <DeviceFamilyRow
              key={family.id}
              family={family}
              isModelChecked={isModelChecked}
              onModelChecked={onModelChecked}
            />
          ))}
      </li>
    );
  }
}
