// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { RegionsModal } from "pages/_shared/RegionsModal/RegionsModal";
import { trans } from "src/translations";

import { DistributionContext } from "../../Context/distributionContext";
import {
  DEVICE_TYPE_ALL,
  DEVICE_TYPE_DISTRIBUTOR,
  TARGET_FIELD_MODE,
  DEVICE_TYPE_NOT_ASSIGNED,
} from "../constants";
import { TargetFieldModePropType } from "../propTypes";
import { DevicesModalData } from "./DevicesModalData";

const createDevicesMap = (devices) => {
  return devices.reduce((acc, device) => {
    return { ...acc, [device.id]: device };
  }, {});
};

const filterDevicesMap = (obj, devices) => {
  const alreadySelectedIds = devices.map((device) => device.id);
  return Object.fromEntries(
    Object.entries(obj).filter(([id]) => !alreadySelectedIds.includes(id))
  );
};

export class TargetsModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    allCountries: PropTypes.array,
    onSaveTarget: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    initSelectedDevices: PropTypes.array,
    targetFieldMode: TargetFieldModePropType,
  };

  getInitialMode = () => {
    const { targetFieldMode } = this.props;
    if (targetFieldMode === TARGET_FIELD_MODE.ALL_DEVICES) {
      return DEVICE_TYPE_ALL;
    }
    return DEVICE_TYPE_DISTRIBUTOR;
  };

  state = {
    isRegionsModalOpen: false,
    selectedDevices: {},
    selectedCountries: [],
    mode: DEVICE_TYPE_NOT_ASSIGNED,
  };

  componentDidUpdate(prevProps) {
    const { initSelectedDevices, isEdit, isOpen } = this.props;
    if (!prevProps.isOpen && isOpen && isEdit) {
      const mode = initSelectedDevices.type || initSelectedDevices[0].type;

      const selectedItem = {
        selectedDevices: createDevicesMap(initSelectedDevices),
      };

      this.setState({ mode, ...selectedItem });
    }
    if (!prevProps.isOpen && isOpen && !isEdit) {
      this.setInitialMode();
    }
  }

  setInitialMode = () => this.setState({ mode: this.getInitialMode() });

  toggleRegionsModal = () => {
    this.setState(({ isRegionsModalOpen }) => ({
      isRegionsModalOpen: !isRegionsModalOpen,
    }));
  };

  handleNext = () => {
    const { onClose, onSaveTarget, isEdit } = this.props;
    const { mode, selectedDevices } = this.state;
    onClose();
    if (isEdit) {
      onSaveTarget(Object.values(selectedDevices), [], mode);
      this.setState({ mode: DEVICE_TYPE_NOT_ASSIGNED });
    } else {
      this.toggleRegionsModal();
    }
  };

  handleChangeMode = (e) => {
    this.handleClearSelectedDevices();
    this.setState({ mode: e.target.value });
  };

  toggleManyDevices = (devices, isCheck) => {
    if (isCheck) {
      this.setState((prevState) => ({
        selectedDevices: {
          ...prevState.selectedDevices,
          ...createDevicesMap(devices),
        },
      }));
    } else {
      this.setState((prevState) => ({
        selectedDevices: {
          ...filterDevicesMap(prevState.selectedDevices, devices),
        },
      }));
    }
  };

  handleRegionSelect = (region) => {
    const { onSaveTarget } = this.props;
    const { selectedDevices, mode } = this.state;
    this.toggleRegionsModal();
    this.handleClearSelectedDevices();
    this.setState({ mode: DEVICE_TYPE_NOT_ASSIGNED });
    onSaveTarget(Object.values(selectedDevices), region, mode);
  };

  isDeviceChecked = (device) => {
    const { selectedDevices } = this.state;
    return Boolean(selectedDevices[device.id]);
  };

  handleClearSelectedDevices = () => this.setState({ selectedDevices: {} });

  handleClose = () => {
    const { onClose } = this.props;
    this.handleClearSelectedDevices();
    this.setState({ mode: DEVICE_TYPE_NOT_ASSIGNED });
    onClose();
  };

  render() {
    const { isRegionsModalOpen, selectedDevices, selectedCountries, mode } =
      this.state;
    const { isOpen, allCountries, isEdit, targetFieldMode } = this.props;

    return (
      <Fragment>
        <DistributionContext.Consumer>
          {(prop) => (
            <DevicesModalData
              useDistributorFilter={prop?.useDistributorFilter} // it gives as ability to overwrite this by passing props (useful while testing)
              {...this.props}
              isOpen={isOpen}
              onClose={this.handleClose}
              onNext={this.handleNext}
              onToggleManyDevices={this.toggleManyDevices}
              isDeviceChecked={this.isDeviceChecked}
              selectedDevices={selectedDevices}
              mode={mode}
              onChangeMode={this.handleChangeMode}
              hasNext={!isEdit}
              targetFieldMode={targetFieldMode}
            />
          )}
        </DistributionContext.Consumer>
        <RegionsModal
          label={trans.TARGETS__REGIONS_MODAL_CONTENT()}
          selectedCountriesCodes={selectedCountries}
          isOpen={isRegionsModalOpen}
          onClose={this.toggleRegionsModal}
          onSelect={this.handleRegionSelect}
          allCountries={allCountries}
        />
      </Fragment>
    );
  }
}
