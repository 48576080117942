// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";
import queryString from "query-string";

import { createExportQueryParams } from "components/layout";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { withQueryParams, withRouter } from "utils/decorators";
import { createBody } from "utils/jsonApi";

import { DEFAULT_PLAN_STATUSES } from "./constants";
import { DistributionAppListRend } from "./DistributionAppListRend";

@withRouter
@withQueryParams
@connect(null, (dispatch) => ({
  updateAppStatusesForDefaultPlan: (body) =>
    dispatch(
      apiThatThrows.updateAppStatusesForDefaultPlan.action({
        options: { body },
      })
    ),
  postBulkCustomPlanApplications: (body) =>
    dispatch(
      apiThatThrows.postBulkCustomPlanApplications.action({
        options: { body },
      })
    ),
  exportMyDistributionApplicationsPaginated: async (queryParams) => {
    await dispatch(
      apiThatThrows.exportMyDistributionApplicationsPaginated.action({
        queryParams,
      })
    );
  },
}))
export class DistributionAppListLogic extends Component {
  static propTypes = {
    applications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        defaultPlanStatus: PropTypes.string.isRequired,
        defaultPlanStatusInconsistent: PropTypes.bool.isRequired,
      })
    ),
    count: PropTypes.number,
    error: PropTypes.object,
    loading: PropTypes.bool,
    refreshData: PropTypes.func.isRequired,
    availableFilters: PropTypes.object.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @connect
    updateAppStatusesForDefaultPlan: PropTypes.func.isRequired,
    postBulkCustomPlanApplications: PropTypes.func.isRequired,
    exportMyDistributionApplicationsPaginated: PropTypes.func.isRequired,
  };

  static defaultProps = {
    applications: [],
    count: 0,
    error: null,
    loading: false,
  };

  state = {
    approveForDefPlanProgress: {},
    rejectForDefPlanProgress: {},
    info: null,
  };

  componentDidUpdate(prevProps) {
    const { queryParams } = this.props;
    if (!isEqual(prevProps.queryParams, queryParams)) {
      this.setState({ info: null });
    }
  }

  setStateInObject = (stateKey, objectKey, value) => {
    this.setState((prevState) => ({
      [stateKey]: {
        ...prevState[stateKey],
        [objectKey]: value,
      },
    }));
  };

  setInfoMessage(type, text) {
    this.setState({
      info: { type, text },
    });
  }

  handleApproveForDefPlan = async (app) => {
    const { updateAppStatusesForDefaultPlan, refreshData } = this.props;

    try {
      this.setState({ info: null });
      this.setStateInObject("approveForDefPlanProgress", app.id, true);

      const body = createBody([
        {
          type: "default-plans-applications",
          id: app.id,
          status: DEFAULT_PLAN_STATUSES.approved,
        },
      ]);

      await updateAppStatusesForDefaultPlan(body);

      this.setInfoMessage(
        "normal",
        trans.APP_LIST_DISTRIBUTION__APPROVE_FOR_DEFAULT_SUCCESS({
          appName: app.name,
        })
      );
    } catch (err) {
      this.setInfoMessage(
        "error",
        trans.APP_LIST_DISTRIBUTION__APPROVE_FOR_DEFAULT_ERROR()
      );
    } finally {
      this.setStateInObject("approveForDefPlanProgress", app.id, false);
      refreshData();
    }
  };

  handleRejectForDefPlan = async (app) => {
    const { updateAppStatusesForDefaultPlan, refreshData } = this.props;

    try {
      this.setState({ info: null });
      this.setStateInObject("rejectForDefPlanProgress", app.id, true);

      const body = createBody([
        {
          type: "default-plans-applications",
          id: app.id,
          status: DEFAULT_PLAN_STATUSES.rejected,
        },
      ]);

      await updateAppStatusesForDefaultPlan(body);

      this.setInfoMessage(
        "normal",
        trans.APP_LIST_DISTRIBUTION__REJECT_FOR_DEFAULT_SUCCESS({
          appName: app.name,
        })
      );
    } catch (err) {
      this.setInfoMessage(
        "error",
        trans.APP_LIST_DISTRIBUTION__REJECT_FOR_DEFAULT_ERROR()
      );
    } finally {
      this.setStateInObject("rejectForDefPlanProgress", app.id, false);
      refreshData();
    }
  };

  onChangeSearchParams = (newSearchParams) => {
    const { location, navigate } = this.props;
    const searchString = queryString.stringify(newSearchParams);
    navigate(`${location.pathname}?${searchString}`);
  };

  createManageAppsInCustomPlansBody(appIds, { plansAdded, plansRemoved }) {
    const data = [
      {
        id: "add",
        type: "bulk-action",
        attributes: {
          plans: plansAdded,
          apps: appIds,
        },
      },
      {
        id: "remove",
        type: "bulk-action",
        attributes: {
          plans: plansRemoved,
          apps: appIds,
        },
      },
    ];

    // This request has a very unusual structure that does not conform with
    // jsona. We have to skip the serialization step that is part of createBody
    // and just send raw data converted to string.
    return JSON.stringify({ data });
  }

  manageAppsInCustomPlans = async (appIds, planChanges) => {
    const { postBulkCustomPlanApplications } = this.props;

    const body = this.createManageAppsInCustomPlansBody(appIds, planChanges);
    await postBulkCustomPlanApplications(body);

    const successMessage =
      appIds.length === 1
        ? trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_SUCCESS_ONE()
        : trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_SUCCESS_MANY({
            appsCount: appIds.length,
          });
    this.setInfoMessage("normal", successMessage);
  };

  createNewCustomDistributionPlan = (appIds) => {
    const { navigate } = this.props;
    navigate(
      {
        pathname: "/distribution/custom-plans/create",
      },
      {
        state: { appIds },
      }
    );
  };

  onExport = async ({ fileFormat }) => {
    const { exportMyDistributionApplicationsPaginated, queryParams } =
      this.props;

    const exportQueryParams = createExportQueryParams({
      fileFormat,
      queryParams,
    });

    await exportMyDistributionApplicationsPaginated(exportQueryParams);
  };

  render() {
    const {
      applications,
      count,
      error,
      loading,
      availableFilters,
      getSearchSuggestions,
    } = this.props;

    return (
      <DistributionAppListRend
        applications={applications}
        count={count}
        loading={loading}
        error={error}
        onApproveForDefPlan={this.handleApproveForDefPlan}
        onRejectForDefPlan={this.handleRejectForDefPlan}
        onChangeSearchParams={this.onChangeSearchParams}
        availableFilters={availableFilters}
        onManageAppsInCustomPlans={this.manageAppsInCustomPlans}
        onCreateNewCustomDistributionPlan={this.createNewCustomDistributionPlan}
        approveForDefPlanProgress={this.state.approveForDefPlanProgress}
        rejectForDefPlanProgress={this.state.rejectForDefPlanProgress}
        getSearchSuggestions={getSearchSuggestions}
        info={this.state.info}
        onExport={this.onExport}
      />
    );
  }
}
