// Copyright © 2021 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import ReactDOMServer from "react-dom/server";

export interface HtmlTranslationProps {
  className?: string;
  component?: keyof JSX.IntrinsicElements;
  transFunc: (params: Record<string, string | number>) => string;
  transParams?: Record<string, any>;
  dataTestId: string;
}

export class HtmlTranslation extends Component<HtmlTranslationProps> {
  static defaultProps = {
    component: "div",
  };

  getStaticTransParams() {
    const { transParams } = this.props;

    const staticTransParams: Record<string, any> = {};
    for (const [key, value] of Object.entries(transParams ?? {})) {
      staticTransParams[key] = ReactDOMServer.renderToStaticMarkup(value);
    }

    return staticTransParams;
  }

  render() {
    const { className, component, transFunc, dataTestId } = this.props;
    const Component = component as keyof JSX.IntrinsicElements;

    return (
      <Component
        className={className}
        dangerouslySetInnerHTML={{
          __html: transFunc(this.getStaticTransParams()),
        }}
        // eslint-disable-next-line react/forbid-component-props
        data-test-id={dataTestId}
      />
    );
  }
}
