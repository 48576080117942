// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import image404 from "src/images/404.png";
import { trans } from "src/translations";

import styles from "./PageContent404.scss";

export const PageContent404 = ({ className }) => (
  <div className={className} data-test-id="not-found">
    <div className={styles.image404Wrapper}>
      <img
        className={styles.image404}
        src={image404}
        alt={trans.PAGE_404__IMAGE_ALT()}
      />
    </div>
    <h1 className={styles.header}>{trans.PAGE_404__CONTENT_HEADER()}</h1>
    <p className={styles.text}>{trans.PAGE_404__LINE_1()}</p>
    <p className={styles.text}>{trans.PAGE_404__LINE_2()}</p>
  </div>
);

PageContent404.propTypes = {
  className: PropTypes.string,
};
