// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  CheckAllButton,
  SelectedCountFooter,
  SearchableList,
} from "components/elements";
import { trans } from "src/translations";

import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
  DEVICE_TYPE_ALL,
} from "../constants";
import { AllDevices } from "./AllDevices";
import styles from "./DevicesList.scss";
import { DevicesRow } from "./DevicesRow";

const LIST_HEADER_MODES = {
  [DEVICE_TYPE_MODEL]: [trans.DEVICE_NAME(), trans.ORGANIZATION()],
  [DEVICE_TYPE_FAMILY]: [trans.DEVICE_FAMILY_NAME(), trans.ORGANIZATION()],
  [DEVICE_TYPE_DISTRIBUTOR]: [trans.ORGANIZATION_NAME()],
};

export class DevicesList extends Component {
  static propTypes = {
    models: PropTypes.array,
    families: PropTypes.array,
    distributors: PropTypes.array,
    isChecked: PropTypes.func,
    toggleMany: PropTypes.func,
    selectedCount: PropTypes.number,
    mode: PropTypes.string,
    loading: PropTypes.bool,
  };

  toggleMany = (items) => (value) => {
    const { toggleMany } = this.props;
    toggleMany(
      items.map((e) => e.id),
      value
    );
  };

  toggleOne = (device, value) => this.toggleMany([device])(value);

  getDevices = () => {
    const { families, models, distributors, mode } = this.props;
    switch (mode) {
      case DEVICE_TYPE_FAMILY:
        return families;
      case DEVICE_TYPE_MODEL:
        return models;
      case DEVICE_TYPE_DISTRIBUTOR:
        return distributors;
      default:
        return [];
    }
  };

  renderListHeader = (visibleItems) => {
    const { isChecked, mode } = this.props;
    const columnHeaders = LIST_HEADER_MODES[mode] ?? [];

    return (
      <div className={styles.listHeader}>
        <div className={styles.checkAllOption}>
          <CheckAllButton
            fieldName="targets-devices-list"
            onChange={this.toggleMany(visibleItems)}
            checked={visibleItems.every(isChecked)}
            disabled={visibleItems.length === 0}
            className={styles.checkAllButton}
            withLabel={false}
          />
        </div>
        <div
          className={styles.columnHeaders}
          data-test-id="targets-device-list-header"
        >
          {columnHeaders.map((header) => (
            <div key={header}>{header}</div>
          ))}
        </div>
      </div>
    );
  };

  renderListRow = (item) => {
    const { isChecked } = this.props;

    return (
      <DevicesRow
        key={item.id}
        item={item}
        isChecked={isChecked(item)}
        onChecked={this.toggleOne}
      />
    );
  };

  render() {
    const { selectedCount, mode, loading } = this.props;

    if (mode === DEVICE_TYPE_ALL) {
      return <AllDevices />;
    }

    const devices = this.getDevices();
    return (
      <>
        <SearchableList
          list={devices}
          searchBy="name"
          classNameList={styles.list}
          withLoader={true}
          loading={loading}
          optionsListElement={this.renderListHeader}
        >
          {(item) => this.renderListRow(item)}
        </SearchableList>
        <SelectedCountFooter selectedCount={selectedCount} />
      </>
    );
  }
}
