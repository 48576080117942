// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const DEVICE_TYPE_MODEL = "model";
export const DEVICE_TYPE_FAMILY = "family";
export const DEVICE_TYPE_DISTRIBUTOR = "distributor";
export const DEVICE_TYPE_ALL = "all";
export const DEVICE_TYPE_NOT_ASSIGNED = "not_assigned";
export const TARGETS_FIELD_NAME = "targets";

/**
 * Target Fields works in 2 different modes:
 * 1. ALL_DEVICES
 *      - for basic targeting and all devices mode
 * 3. NON_GRANUALR
 *      - for basic targeting
 */
export const TARGET_FIELD_MODE = {
  ALL_DEVICES: "all-devices",
  NON_GRANULAR: "non-granular",
};
