import { FieldArray, useFormikContext } from "formik";
import partition from "lodash-es/partition";
import PropTypes from "prop-types";

import { Label, ValidationBadge } from "components/form";
import { Checkbox } from "components/form/elements";
import { TextField } from "components/form/fields";
import styles from "components/form/form.scss";
import { trans } from "src/translations";
import { classes } from "utils/classes";

const FORMAT_OTHER = "other";
const LABELS = {
  metadata_video_formats: {
    label: trans.APP_EDIT__VIDEO_FORMATS_LABEL(),
    key: "format",
    dataTestId: "format",
  },
  metadata_video_adaptive_streamings: {
    label: trans.APP_EDIT__ADAPTIVE_STREAMINGS_LABEL(),
    key: "streaming",
    dataTestId: "streaming",
  },
  metadata_video_drms: {
    label: trans.APP_EDIT__DRMS_LABEL(),
    key: "drm",
    dataTestId: "drm",
  },
};

const isOtherOption = (item) => item?.slug === FORMAT_OTHER;
const prepareSupportData = (data) => {
  const [otherOption, options] = partition(data, isOtherOption);

  return [...options, ...otherOption].map((format) => ({
    value: format.slug,
    label: format.human_name,
  }));
};

export const VideoOptions = ({
  videoFormatsError,
  videoDrms,
  videoFormats,
  adaptiveStreamings,
  disabled,
  handleOnChange,
  isCheckedVideoOption,
}) => {
  const { values, getFieldMeta } = useFormikContext();

  const videoOptions = {
    metadata_video_formats: videoFormats,
    metadata_video_adaptive_streamings: adaptiveStreamings,
    metadata_video_drms: videoDrms,
  };

  const renderOtherInput = (fieldName, key, dataTestId) => {
    const { value: fieldValues } = getFieldMeta(fieldName);
    const index = isCheckedVideoOption(fieldValues, key, FORMAT_OTHER);

    if (index > -1) {
      return (
        <TextField
          name={`${fieldName}[${index}].comment`}
          label={trans.PLEASE_SPECIFY()}
          dataTestId={`${dataTestId}-other-field`}
        />
      );
    }
    return null;
  };

  const fieldsKeys = Object.keys(videoOptions);

  return (
    <div
      className={classes(styles.videoFormats, {
        [styles.disabled]: disabled,
      })}
      data-test-id="video-options"
    >
      {fieldsKeys.map((videoOption) => {
        const supportedFormats = prepareSupportData(videoOptions[videoOption]);
        return (
          <div
            key={videoOption}
            className={styles.field}
            data-test-id="video-option"
          >
            <Label text={LABELS[videoOption].label} />
            <FieldArray
              name={`metadata.${videoOption}`}
              render={(arrayHelpers) => {
                return supportedFormats.map(({ label, value }) => {
                  return (
                    <Checkbox
                      key={`key-${value}`}
                      dataTestId={`${LABELS[videoOption].dataTestId}`}
                      name={`metadata.${videoOption}`}
                      value={value}
                      label={label}
                      checked={
                        isCheckedVideoOption(
                          values["metadata"][videoOption],
                          LABELS[videoOption].key,
                          value
                        ) !== -1
                      }
                      onChange={(e) =>
                        handleOnChange(e, LABELS[videoOption].key, arrayHelpers)
                      }
                      disabled={disabled}
                    />
                  );
                });
              }}
            />
            {renderOtherInput(
              `metadata.${videoOption}`,
              LABELS[videoOption].key,
              LABELS[videoOption].dataTestId
            )}
          </div>
        );
      })}
      {videoFormatsError && !disabled ? (
        <ValidationBadge error={videoFormatsError} touched={true} />
      ) : null}
    </div>
  );
};

VideoOptions.propTypes = {
  videoFormatsError: PropTypes.string,
  videoDrms: PropTypes.array.isRequired,
  videoFormats: PropTypes.array.isRequired,
  adaptiveStreamings: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  isCheckedVideoOption: PropTypes.func.isRequired,
};
