// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { TagsList } from "components/elements";
import { trans } from "src/translations";

import {
  FILTER_EXCLUSION_TRIGGER_CHARACTER,
  FILTER_TRIGGER_CHARACTER,
} from "../constants";
import styles from "./FiltersList.scss";

export class FiltersList extends PureComponent {
  static propTypes = {
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        displayValue: PropTypes.string.isRequired,
      })
    ),
    exclusionFilters: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        displayValue: PropTypes.string.isRequired,
      })
    ),
    onFilterClick: PropTypes.func,
    hasPossibleFilters: PropTypes.bool,
    hasExcludeOption: PropTypes.bool,
  };

  isFilterListEmpty = () =>
    this.props.filters.length === 0 && this.props.exclusionFilters.length === 0;

  filterToTag = (filter, idx, isExclude = false) => ({
    id: idx,
    name: filter.displayName,
    value: filter.displayValue,
    isExclude,
  });

  renderFiltersLabel = (label) => (
    <span className={styles.label}>{`${label}:`}</span>
  );
  renderCurrentFilters = () => {
    const { filters, exclusionFilters, onFilterClick } = this.props;

    if ((!filters && !exclusionFilters) || this.isFilterListEmpty()) {
      return null;
    }

    return (
      <div className={styles.tagsContainer}>
        <TagsList
          label={this.renderFiltersLabel(trans.SEARCH_BAR__INCLUDE_LABEL())}
          className={styles.filtersTagList}
          tags={
            filters &&
            filters
              .map(this.filterToTag)
              .sort((a, b) => a.name.localeCompare(b.name))
          }
          additionalTags={
            exclusionFilters &&
            exclusionFilters
              .map((el, i) => this.filterToTag(el, i, true))
              .sort((a, b) => a.name.localeCompare(b.name))
          }
          additionalTagsLabel={this.renderFiltersLabel(
            trans.SEARCH_BAR__EXCLUDE_LABEL()
          )}
          additionalTagClassName={styles.filtersExcludeTagList}
          onDeleteClick={onFilterClick}
        />
      </div>
    );
  };

  renderFilterHint = () => (
    <Fragment>
      {this.props.hasExcludeOption ? (
        <div
          className={styles.filterHint}
          data-test-id="filters-list-exclude-label"
        >
          {trans.SEARCH_BAR__FILTER_TOOLTIP_PART_1()} &quot;
          <span className={styles.bold}>
            {FILTER_TRIGGER_CHARACTER}{" "}
          </span>&quot; {trans.SEARCH_BAR__EXCLUDE_FILTER_TOOLTIP_PART_2()}{" "}
          &quot;
          <span className={styles.bold}>
            {FILTER_EXCLUSION_TRIGGER_CHARACTER}
          </span>
          &quot; {trans.SEARCH_BAR__EXCLUDE_FILTER_TOOLTIP_PART_3()}
        </div>
      ) : (
        <div
          className={styles.filterHint}
          data-test-id="filters-list-include-label"
        >
          {trans.SEARCH_BAR__FILTER_TOOLTIP_PART_1()}{" "}
          <span className={styles.bold}>{FILTER_TRIGGER_CHARACTER}</span>{" "}
          {trans.SEARCH_BAR__FILTER_TOOLTIP_PART_2()}
        </div>
      )}
    </Fragment>
  );

  render() {
    const { hasPossibleFilters } = this.props;

    return (
      <div className={styles.container}>
        {this.renderCurrentFilters()}
        <div className={styles.filterHintContainer}>
          {hasPossibleFilters && this.renderFilterHint()}
        </div>
      </div>
    );
  }
}

export default FiltersList;
