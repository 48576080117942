// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Status } from "components/feedback";
import { Checkbox } from "components/form/elements";
import { trans } from "src/translations";

import styles from "./DeviceModelRow.scss";

export class DeviceModelRow extends Component {
  static propTypes = {
    model: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isTesting: PropTypes.bool.isRequired,
      isDisabled: PropTypes.bool.isRequired,
    }).isRequired,
    isModelChecked: PropTypes.func.isRequired,
    onModelChecked: PropTypes.func.isRequired,
  };

  handleChange = () => {
    const { model, onModelChecked } = this.props;
    return onModelChecked(model.id);
  };

  isChecked() {
    const { model, isModelChecked } = this.props;
    return isModelChecked(model.id);
  }

  render() {
    const {
      model: { name, isTesting, isDisabled },
    } = this.props;

    return (
      <label className={styles.container} data-test-id="asset-device-model-row">
        <Checkbox
          onChange={this.handleChange}
          checked={this.isChecked()}
          value={name}
          className={styles.checkbox}
        />
        <span className={styles.name}>
          {name} {isTesting && <Status>{trans.TEST_DEVICE()}</Status>}{" "}
          {isDisabled && <Status>{trans.DISABLED()}</Status>}
        </span>
      </label>
    );
  }
}
