// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";
import { isScrolledIntoView, scrollToBottom, scrollToTop } from "utils/scroll";

import { NONE_ITEM_IDX } from "./constants";
import styles from "./listItem.scss";

export class ListItem extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    idx: PropTypes.number.isRequired,
    highlightedItemIdx: PropTypes.number.isRequired,
    selectItem: PropTypes.func.isRequired,
    changeHighlightedItemIdx: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.scrollToHighlightedItem();
  };

  componentDidUpdate(prevProps) {
    const { highlightedItemIdx } = this.props;

    if (prevProps.highlightedItemIdx !== highlightedItemIdx) {
      const isScrolledDown = highlightedItemIdx > prevProps.highlightedItemIdx;
      this.scrollToHighlightedItem(isScrolledDown);
    }
  }

  scrollToHighlightedItem = (isScrolledDown = true) => {
    const { highlightedItemIdx, idx } = this.props;
    const { parentNode } = this.itemRef;

    if (highlightedItemIdx !== idx || highlightedItemIdx === NONE_ITEM_IDX) {
      return;
    }

    if (isScrolledIntoView(parentNode, this.itemRef)) {
      return;
    }

    if (isScrolledDown) {
      scrollToBottom(parentNode, this.itemRef);
    } else {
      scrollToTop(parentNode, this.itemRef);
    }
  };

  setRef = (itemRef) => {
    this.itemRef = itemRef;
  };

  handleMouseDown = () => {
    const { selectItem, idx } = this.props;
    selectItem(idx);
  };

  handleOnMouseEnter = () => {
    const { changeHighlightedItemIdx, idx } = this.props;
    changeHighlightedItemIdx(idx);
  };

  handleOnMouseLeave = () => {
    const { changeHighlightedItemIdx } = this.props;
    changeHighlightedItemIdx(NONE_ITEM_IDX);
  };

  render() {
    const { label, idx, highlightedItemIdx } = this.props;

    return (
      <div
        ref={this.setRef}
        className={classes(styles.item, {
          [styles.highlighted]: idx === highlightedItemIdx,
        })}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        data-test-id="autocomplete-list-item"
      >
        {label}
      </div>
    );
  }
}
