// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Tooltip } from "components/popups";

import styles from "../_shared/UsersTable.scss";
import { getUserRolesString } from "../_utils";

const renderRoleTooltip = (roles) => (
  <ul>
    {roles.map((type) => (
      <li key={type.role}>{type.role},</li>
    ))}
  </ul>
);

export const RolesColumn = ({ roles }) => (
  <Tooltip
    disabled={!roles || roles.length === 0}
    alignment="left"
    content={renderRoleTooltip(roles)}
    className={styles.roleColumnWrapper}
  >
    <div className={styles.roleColumnContent} data-test-id="user-role">
      {getUserRolesString(roles)}
    </div>
  </Tooltip>
);

RolesColumn.propTypes = {
  roles: PropTypes.array.isRequired,
};
