// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable react/prop-types */
import { Fragment, Component } from "react";

import PropTypes from "prop-types";
import queryString from "query-string";

import { SearchBarWithFilters } from "components/elements";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import { MessageDetailsRow } from "./MessageDetailsRow";
import { MessageHeadersRow } from "./MessageHeadersRow";
import styles from "./MessagesList.scss";

/** We will hide this filter from selectable list and add it by hand  */
const FILTER_ID_APPLICATION = "application";

@withRouter
export class MessagesList extends Component {
  static propTypes = {
    messages: PropTypes.object,
    markMessageAsRead: PropTypes.func.isRequired,
    messageFilters: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    messages: {
      result: [],
      meta: { count: 0 },
    },
    messageFilters: [],
  };

  static tabsConfig = [
    {
      dataTestId: "messages-list",
      columns: [
        { id: "author", label: trans.APP_DETAILS_MESSAGES__COLUMN_AUTHOR() },
        { id: "type", label: trans.APP_DETAILS_MESSAGES__COLUMN_TYPE() },
        {
          id: "date",
          label: trans.APP_DETAILS_MESSAGES__COLUMN_DATE(),
          className: styles.DateColumn,
        },
      ],
    },
  ];

  state = {
    expandedMessages: {},
  };

  isExpanded(messageId) {
    const { expandedMessages } = this.state;
    return Boolean(expandedMessages[messageId]);
  }

  setExpanded = (messageId, nextExpanded) => {
    const { markMessageAsRead } = this.props;

    if (nextExpanded && this.isUnread(messageId)) {
      markMessageAsRead(messageId);
    }

    this.setState((state) => ({
      expandedMessages: {
        ...state.expandedMessages,
        [messageId]: nextExpanded,
      },
    }));
  };

  isUnread(messageId) {
    const { messages } = this.props;
    const message = messages.result.find((m) => m.id === messageId);
    return message && !message.wasRead;
  }

  onSearchBarChange = (searchParams) => {
    const {
      location: { pathname },
      navigate,
    } = this.props;
    const searchString = queryString.stringify(searchParams);
    navigate(`${pathname}?${searchString}`);
  };

  createFilters() {
    // TODO YGG-427 verify with backend that all users can use following filters.
    const isFilterAllowed = (f) => f.id !== FILTER_ID_APPLICATION;
    const messageFilters = this.props.messageFilters.filter(isFilterAllowed);

    const createFilterOption = (opt) => ({
      id: opt.id,
      displayName: opt.id,
      param: "",
      description: opt.description,
      values: opt.values,
    });

    const section = {
      title: trans.APP_DETAILS_MESSAGES__FILTERS_SECTION_NAME(),
      options: messageFilters.map(createFilterOption),
    };

    return { sections: [section] };
  }

  renderBody = () => {
    const { messages } = this.props;

    return (
      <TableBody>
        {messages.result.reduce((acc, message) => {
          const className = message.wasRead
            ? styles.MessageRead
            : styles.MessageUnread;

          acc.push(
            <MessageHeadersRow
              key={message.id}
              className={className}
              expanded={this.isExpanded(message.id)}
              setExpanded={this.setExpanded}
              {...message}
            />
          );

          acc.push(
            <MessageDetailsRow
              key={`${message.id}--details`}
              className={className}
              expanded={this.isExpanded(message.id)}
              setExpanded={this.setExpanded}
              {...message}
            />
          );

          return acc;
        }, [])}
      </TableBody>
    );
  };

  render() {
    const { loading, error, messages } = this.props;

    return (
      <Fragment>
        <Section>
          <SearchBarWithFilters
            placeholder={trans.APP_DETAILS_MESSAGES__SEARCH_PLAEHOLDER()}
            look="for-white-bg"
            onSearch={this.onSearchBarChange}
            filtersDataChoices={this.createFilters()}
          />
        </Section>
        <TabbedTable
          tabsConfig={MessagesList.tabsConfig}
          data={{
            results: messages.result,
            count: messages.meta.count,
            loading,
            error,
          }}
          renderTableBody={this.renderBody}
        />
      </Fragment>
    );
  }
}
