// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { classes } from "utils/classes";

import { NONE_ITEM_IDX, KEY_CODES } from "./constants";
import styles from "./search.scss";

export class Search extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    changeHighlightedItemIdx: PropTypes.func.isRequired,
    highlightedItemIdx: PropTypes.number.isRequired,
    selectItem: PropTypes.func.isRequired,
    itemsCount: PropTypes.number.isRequired,
    selectedItem: PropTypes.object,
    changeIsListOpen: PropTypes.func.isRequired,
    withoutIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    // used if value from outside item list is acceptable
    isCustomValueAccepted: PropTypes.bool,

    // prop required for Formik managed field
    name: PropTypes.string,
  };

  static defaultProps = {
    placeholder: "",
    withoutIcon: false,
    isCustomValueAccepted: false,
  };

  setRef = (inputRef) => {
    this.inputRef = inputRef;
  };

  handleChange = (ev) => {
    const { changeSearch } = this.props;

    const value = ev.target.value;
    changeSearch(value);
  };

  handleBlur = () => {
    const {
      changeSearch,
      selectedItem,
      highlightedItemIdx,
      changeIsListOpen,
      selectItem,
      isCustomValueAccepted,
    } = this.props;

    changeIsListOpen(false);

    // if some item is highlighted during blur, select it
    if (highlightedItemIdx !== NONE_ITEM_IDX) {
      selectItem(highlightedItemIdx);
      return;
    }

    // reset to previously selected item
    if (selectedItem) {
      changeSearch(selectedItem.label);
    } else if (!isCustomValueAccepted) {
      this.clear();
    }
  };

  handleFocus = () => {
    const { changeIsListOpen, changeHighlightedItemIdx } = this.props;

    changeIsListOpen(true);
    changeHighlightedItemIdx(NONE_ITEM_IDX);
  };

  handleKeyDown = (ev) => {
    const {
      changeHighlightedItemIdx,
      highlightedItemIdx,
      selectItem,
      itemsCount,
    } = this.props;

    switch (ev.keyCode) {
      case KEY_CODES.arrowDown: {
        const nextIdx = highlightedItemIdx + 1;
        if (nextIdx < itemsCount) {
          changeHighlightedItemIdx(nextIdx);
        }
        break;
      }
      case KEY_CODES.enter: {
        if (highlightedItemIdx !== NONE_ITEM_IDX) {
          selectItem(highlightedItemIdx);
        }
        this.inputRef.blur();
        break;
      }
      case KEY_CODES.arrowUp: {
        if (highlightedItemIdx > -1) {
          changeHighlightedItemIdx(highlightedItemIdx - 1);
        }
        break;
      }
      default:
        break;
    }
  };

  handleClear = () => {
    this.clear();
  };

  clear = () => {
    const { selectItem } = this.props;
    selectItem(NONE_ITEM_IDX);
  };

  render() {
    const { value, placeholder, selectedItem, withoutIcon, disabled, name } =
      this.props;

    let searchImg = "";
    if (selectedItem) {
      searchImg = selectedItem.img;
    }

    const isItemSelected = Boolean(selectedItem);

    return (
      <div
        className={classes(styles.wrapper, {
          [styles.selected]: isItemSelected,
          [styles.withImg]: searchImg,
        })}
        data-test-id="autocomplete-search"
      >
        {searchImg && <img className={styles.img} src={searchImg} />}
        <input
          ref={this.setRef}
          value={value}
          className={classes(styles.input, styles.normal)}
          placeholder={placeholder}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          disabled={disabled}
          name={name}
          autoComplete="off"
          data-test-id="autocomplete-search-input"
        />
        {!withoutIcon && !disabled && (
          <div className={styles.icon} onClick={this.handleClear}>
            <GrabsonIcon
              name={isItemSelected ? "close" : "search"}
              font="micro"
              size="normal"
            />
          </div>
        )}
      </div>
    );
  }
}
