// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { FieldArray, Field } from "formik";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { ValidationBadge } from "components/form";
import { Table, TableBody, TableRow, TableRowColumn } from "components/layout";
import { trans } from "src/translations";
import { withCountries } from "utils/continents";

import { createTarget } from "./_utils";
import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
  TARGETS_FIELD_NAME,
  TARGET_FIELD_MODE,
  DEVICE_TYPE_ALL,
} from "./constants";
import { TargetFieldModePropType } from "./propTypes";
import styles from "./TargetsField.scss";
import { TargetsModal } from "./TargetsModal/TargetsModal";
import { TargetsRow } from "./TargetsRow";

@withCountries
export class TargetsField extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    devicesDistributorId: PropTypes.string.isRequired,
    forAdmin: PropTypes.bool,
    readonly: PropTypes.bool,
    mode: TargetFieldModePropType,

    // from @withCountries
    allCountries: PropTypes.array.isRequired,
    countriesLoading: PropTypes.bool.isRequired,
    countriesError: PropTypes.object,
  };

  static defaultProps = {
    disabled: false,
    forAdmin: false,
    mode: TARGET_FIELD_MODE.NON_GRANULAR,
  };

  state = {
    isDeviceModalOpen: false,
    fieldIdxForDeviceModal: undefined,
  };

  closeDeviceModal = () => {
    this.setState({
      isDeviceModalOpen: false,
      fieldIdxForDeviceModal: undefined,
    });
  };

  openDevicesModal = (fieldIdx) => {
    this.setState({
      isDeviceModalOpen: true,
      fieldIdxForDeviceModal: fieldIdx,
    });
  };

  getHandleSaveTarget =
    (targets, arrayHelpers) => (devices, countries, mode) => {
      const { fieldIdxForDeviceModal } = this.state;
      const newValue = createTarget(
        targets[fieldIdxForDeviceModal],
        devices,
        countries,
        mode
      );
      this.updateField(arrayHelpers, newValue);
      this.closeDeviceModal();
    };

  getHandleUpdateRegions =
    (values, index, { remove, insert }) =>
    (countries) => {
      const updatedTarget = { ...values[index], countries };
      remove(index);
      insert(index, updatedTarget);
    };

  getSelectedDevicesInModal(targets) {
    const { fieldIdxForDeviceModal } = this.state;
    if (fieldIdxForDeviceModal === undefined) {
      return [];
    }

    const target = targets[fieldIdxForDeviceModal];
    const { deviceModels, deviceFamilies, distributors, type } = target;

    switch (type) {
      case DEVICE_TYPE_MODEL:
        return deviceModels;
      case DEVICE_TYPE_FAMILY:
        return deviceFamilies;
      case DEVICE_TYPE_DISTRIBUTOR:
        return distributors;
      case DEVICE_TYPE_ALL:
        return [{ type }];
      default:
        return [];
    }
  }

  updateField = ({ insert, remove, push }, newValue) => {
    const { fieldIdxForDeviceModal } = this.state;
    if (fieldIdxForDeviceModal != null) {
      remove(fieldIdxForDeviceModal);
      insert(fieldIdxForDeviceModal, newValue);
    } else {
      push(newValue);
    }
  };

  renderRowField = ({ arrayHelpers, targets, fieldIdx, value }) => {
    const { allCountries, disabled, readonly } = this.props;
    const handleUpdateRegions = this.getHandleUpdateRegions(
      targets,
      fieldIdx,
      arrayHelpers
    );

    const onSelectDevicesClicked = () => {
      if (!disabled) {
        this.openDevicesModal(fieldIdx);
      }
    };

    return (
      <TargetsRow
        dataTestId={`targets-field-row-${fieldIdx}`}
        target={value}
        onDelete={() => arrayHelpers.remove(fieldIdx)}
        onSelectDevicesClicked={onSelectDevicesClicked}
        allCountries={allCountries}
        disabled={disabled}
        readonly={readonly}
        onSelectCountries={handleUpdateRegions}
      />
    );
  };

  renderFields = ({ form: { values, getFieldMeta }, insert, remove, push }) => {
    const { fieldIdxForDeviceModal, isDeviceModalOpen } = this.state;
    const {
      allCountries,
      countriesError: error,
      countriesLoading: loading,
      devicesDistributorId,
      mode,
      readonly,
      forAdmin,
      disabled,
    } = this.props;

    const { error: fieldValidationError, touched } =
      getFieldMeta(TARGETS_FIELD_NAME);

    const onNewTargetAdded = (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.openDevicesModal(fieldIdxForDeviceModal);
    };

    const targets = values[TARGETS_FIELD_NAME] || [];

    const handleSaveTarget = this.getHandleSaveTarget(targets, {
      insert,
      remove,
      push,
    });

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <div>
        <Table>
          <TableBody>
            {targets.map((target, i) => (
              <Field
                key={target.id}
                name={`${TARGETS_FIELD_NAME}.${i}`}
                value={target}
                component={this.renderRowField}
                targets={targets}
                fieldIdx={i}
                allCountries={allCountries}
                disabled={disabled}
                readonly={readonly}
                getHandleUpdateRegions={this.getHandleUpdateRegions}
                openDevicesModal={this.openDevicesModal}
                arrayHelpers={{ insert, remove, push }}
              />
            ))}

            {targets.length === 0 && (
              <TableRow dataTestId="targets-field-no-targets">
                <TableRowColumn colspan={3}>
                  {trans.TARGETS__NO_SELECTED()}
                </TableRowColumn>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TargetsModal
          key={devicesDistributorId}
          forAdmin={forAdmin}
          isOpen={isDeviceModalOpen}
          onClose={this.closeDeviceModal}
          initSelectedDevices={this.getSelectedDevicesInModal(targets)}
          distributorId={devicesDistributorId}
          allCountries={allCountries}
          onSaveTarget={handleSaveTarget}
          isEdit={fieldIdxForDeviceModal != null}
          targetFieldMode={mode}
        />

        {!disabled && !readonly && (
          <div className={styles.container}>
            <Button
              dataTestId="targets-field-add-new-button"
              className={styles.button}
              onClick={onNewTargetAdded}
            >
              {trans.TARGETS__ADD()}
            </Button>
          </div>
        )}
        <ValidationBadge error={fieldValidationError} touched={touched} />
      </div>
    );
  };

  render() {
    const {
      countriesLoading,
      countriesError,
      allCountries,
      disabled,
      devicesDistributorId,
      forAdmin,
      readonly,
      mode,
    } = this.props;
    const { isDeviceModalOpen } = this.state;

    return (
      <FieldArray
        name={TARGETS_FIELD_NAME}
        component={this.renderFields}
        isDeviceModalOpen={isDeviceModalOpen}
        allCountries={allCountries}
        loading={countriesLoading}
        error={countriesError}
        devicesDistributorId={devicesDistributorId}
        disabled={disabled}
        forAdmin={forAdmin}
        readonly={readonly}
        mode={mode}
      />
    );
  }
}
