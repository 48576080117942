// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Avatar } from "components/images";
import backgroundImg from "containers/Layout/images/bg.png";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./Details.scss";

export class Details extends Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string,
      logo: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "details",
  };

  render() {
    const { className, dataTestId } = this.props;
    const { id, name, description, email, phone, logo } = this.props.data;

    return (
      <div
        className={classes(styles.container, className)}
        data-test-id={dataTestId}
      >
        <img src={backgroundImg} className={styles.backgroundImg} />
        <div className={styles.name} data-test-id={`${dataTestId}-name`}>
          {name}
        </div>
        <Avatar className={styles.logo} src={logo} size="180px" />
        {id && (
          <div className={styles.id} data-test-id={`${dataTestId}-id`}>
            {trans.ORGANIZATION__ID_LABEL()}: {id}
          </div>
        )}
        <div
          className={styles.description}
          data-test-id={`${dataTestId}-description`}
        >
          {description}
        </div>
        <div className={styles.line} />
        <div className={styles.email} data-test-id={`${dataTestId}-email`}>
          {email}
        </div>
        <div className={styles.phone} data-test-id={`${dataTestId}-phone`}>
          {phone}
        </div>
      </div>
    );
  }
}
