// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { DEVICE_TYPE_NOT_ASSIGNED, DEVICE_TYPE_ALL } from "../constants";

const getNames = (i) => i.map((i) => i.name);

const getDistributorName = (distributor) =>
  trans.TARGETS__ALL_DEVICES_FROM_ORGANIZATION({
    orgName: distributor.name,
  });

const concatenateDeviceNames = (target) => {
  const { deviceModels, deviceFamilies, distributors, type } = target;

  if (type === DEVICE_TYPE_ALL) {
    return trans.TARGETS_ALL_DEVICES();
  }

  const names = [
    ...getNames(deviceModels || []),
    ...getNames(deviceFamilies || []),
    ...(distributors || []).map(getDistributorName),
  ];
  return names.join(", ");
};

const isTargetNewlyCreated = (target) => {
  const { type } = target;
  return type === DEVICE_TYPE_NOT_ASSIGNED || !type;
};

export const toDevicesString = (target) => {
  if (isTargetNewlyCreated(target)) {
    return undefined;
  }

  return concatenateDeviceNames(target);
};
