// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import groupBy from "lodash-es/groupBy";
import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";

import { Tag } from "./Tag";

/** Generic tags list that displays tags as list. */
export class TagsList extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * `onDeleteClick` prop will be called with this value. Should be unique
         * for this name-value combination.
         */
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    additionalTags: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * `onDeleteClick` prop will be called with this value. Should be unique
         * for this name-value combination.
         */
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    className: PropTypes.string,
    /** Classname for each rendered `<Tag>` component */
    tagClassName: PropTypes.string,
    additionalTagClassName: PropTypes.string,
    /**
     * Called with `id` of tag that user wants to remove. If not provided,
     * this list is read-only.
     *
     * Type: (id: string) => void
     */
    onDeleteClick: PropTypes.func,
    /**
     * React element to use as action trigger for tags
     */
    label: PropTypes.element,
    additionalTagsLabel: PropTypes.element,
  };

  groupTags = (tags) => {
    const names = tags.map(({ name }) => name);
    const uniqueNames = uniq(names);
    const groupedByName = groupBy(tags, ({ name }) => name);
    return uniqueNames.map((name) => ({
      name,
      values: groupedByName[name].map(({ id, value }) => ({ id, value })),
    }));
  };

  renderTag = (className, isExclude) => {
    const { onDeleteClick } = this.props;
    // ESLint is confused by this component.
    // eslint-disable-next-line react/display-name, react/prop-types
    return ({ name, values }) => (
      <Tag
        key={name}
        name={name}
        isExclude={isExclude}
        values={values}
        className={className}
        onDeleteClick={onDeleteClick}
        dataTestId={"tagList-tag"}
      />
    );
  };

  render() {
    const {
      className,
      tags,
      additionalTags,
      label,
      tagClassName,
      additionalTagClassName,
      additionalTagsLabel,
    } = this.props;

    const groupedTags = this.groupTags(tags);
    const additionalGroupedTags = additionalTags
      ? this.groupTags(additionalTags)
      : null;

    return (
      <ul className={className}>
        {groupedTags.length > 0 && label}
        {groupedTags.map(this.renderTag(tagClassName, false))}
        {additionalGroupedTags &&
          additionalGroupedTags.length > 0 &&
          additionalTagsLabel}
        {additionalGroupedTags &&
          additionalGroupedTags.map(
            this.renderTag(additionalTagClassName, true)
          )}
      </ul>
    );
  }
}
